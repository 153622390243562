import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Helmet } from "react-helmet"
import Title from "../components/elements/title"
import SubTitle from "../components/elements/subtitle"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404" />
    <Helmet>
      <body className="not-found-page" />
    </Helmet>
    <div className="section">
      <div className="container">
        <Title title="Ups! ceva nu a mers bine!" />
        <SubTitle subtitle="Pagina nu a fost gasită..." />
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
